import React, {useEffect, useState} from 'react'
import style from '../home/style.css'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const ENTRY_PRICE = 10
const GUEST_VOUCHER_PRICE = 5
const DONATION_OPTIONS = {
  NONE: 0,
  FIVE: 5,
  TEN: 10,
  TWENTY: 20,
  FIFTY: 50,
  HUNDRED: 100,
}
const PRICES = {
  ENTRY: ENTRY_PRICE_ID,
  GUEST_VOUCHER: GUEST_VOUCHER_PRICE_ID,
  [DONATION_OPTIONS.FIVE]: DONATION_FIVE_PRICE_ID,
  [DONATION_OPTIONS.TEN]: DONATION_TEN_PRICE_ID,
  [DONATION_OPTIONS.TWENTY]: DONATION_TWENTY_PRICE_ID,
  [DONATION_OPTIONS.FIFTY]: DONATION_FIFTY_PRICE_ID,
  [DONATION_OPTIONS.HUNDRED]: DONATION_HUNDRED_PRICE_ID
}

export default () => {
  const [stripe, setStripe] = useState()
  const [entryQuantity, setEntryQuantity] = useState(1)
  const [guestQuantity, setGuestQuantity] = useState(0)
  const [donation, setDonation] = useState(DONATION_OPTIONS.NONE)
  useEffect(() => {
    const maybeStripe = Stripe(STRIPE_PK)
    setStripe(maybeStripe)
  }, [])
  useEffect(() => {
    const params = new URLSearchParams(window.location.href.split('?')[1])
    if (params.has('donation')) setDonation(+params.get('donation'))
    if (params.has('quantity')) setEntryQuantity(+params.get('quantity'))
    if (params.has('guests')) setGuestQuantity(+params.get('guests'))
  }, [])
  if (!stripe) return 'loading payment options'

  const setDonationAndQuery = (don) => {
    if (don === donation) don = DONATION_OPTIONS.NONE
    setDonation(don)
    setQueryParam('donation', don)
  }

  const setQuantityAndQuery = (quant) => {
    setEntryQuantity(quant)
    setQueryParam('quantity', quant)
  }

  const setGuestQuantityAndQuery = (quant) => {
    setGuestQuantity(quant)
    setQueryParam('guests', quant)
  }

  const setQueryParam = (key, val) => {
    if (history.replaceState) {
      const params = new URLSearchParams(window.location.href.split('?')[1])
      params.set(key, val)
      const newurl = `${window.origin}${window.location.pathname}${window.location.hash.split('?')[0]}?${params.toString()}`
      history.replaceState({path: newurl}, '', newurl)
    }
  }

  const sendToCheckout = async () => {
    try {
      const lineItems = [
          {price: PRICES.ENTRY, quantity: entryQuantity}
      ]
      if (guestQuantity) lineItems.push({price: PRICES.GUEST_VOUCHER, quantity: guestQuantity})
      const isDonating = donation !== DONATION_OPTIONS.NONE
      if (isDonating) lineItems.push({price: PRICES[donation], quantity: 1})
      return await stripe.redirectToCheckout({
        lineItems,
        mode: 'payment',
        successUrl: PAYMENT_SUCCESS_URL + (isDonating ? '?donation=true' : ''),
        cancelUrl: window.location.href
      })
    } catch (e) {
      console.log(e.message)
    }
  }
  return (
    <div className={style.checkout}>
      <div className={style.bill}>
        <div style={{fontWeight: 'bold'}}>Description</div>
        <div style={{fontWeight: 'bold'}}>Price</div>
        <div style={{fontWeight: 'bold'}}>Quantity</div>
        <div style={{fontWeight: 'bold'}}>Total</div>
        <div>2022 Wiffle Ball Open Tournament Fee</div>
        <div>{formatter.format(ENTRY_PRICE)}</div>
        <select style={{width: '100%'}} value={entryQuantity} onChange={e => setQuantityAndQuery(+e.target.value)}>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
        </select>
        <div>{formatter.format(ENTRY_PRICE * entryQuantity)}</div>
        <div>Guest Meal Voucher</div>
        <div>{formatter.format(GUEST_VOUCHER_PRICE)}</div>
        <select style={{width: '100%'}} value={guestQuantity} onChange={e => setGuestQuantityAndQuery(+e.target.value)}>
          <option>0</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
        </select>
        <div>{formatter.format(GUEST_VOUCHER_PRICE * guestQuantity)}</div>
        {donation !== DONATION_OPTIONS.NONE && (<div>Donation</div>)}
        {donation !== DONATION_OPTIONS.NONE && (<div>{formatter.format(donation)}</div>)}
        {donation !== DONATION_OPTIONS.NONE && (<div>1</div>)}
        {donation !== DONATION_OPTIONS.NONE && (<div>{formatter.format(donation)}</div>)}
        <div style={{fontWeight: 'bold', gridColumn: 3}}>Grand Total:</div>
        <div style={{fontWeight: 'bold', gridColumn: 4}}>{formatter.format((ENTRY_PRICE * entryQuantity) + (GUEST_VOUCHER_PRICE * guestQuantity) + donation)}</div>
      </div>
      <div style={{alignSelf: 'flex-start', fontWeight: 'bold'}}>Would you like to make a donation?</div>
      <div className={style.donations}>
        <button onClick={() => setDonationAndQuery(DONATION_OPTIONS.FIVE)} className={donation === DONATION_OPTIONS.FIVE ? style.active : ''} type='none'>$5</button>
        <button onClick={() => setDonationAndQuery(DONATION_OPTIONS.TEN)} className={donation === DONATION_OPTIONS.TEN ? style.active : ''} type='none'>$10</button>
        <button onClick={() => setDonationAndQuery(DONATION_OPTIONS.TWENTY)} className={donation === DONATION_OPTIONS.TWENTY ? style.active : ''} type='none'>$20</button>
        <button onClick={() => setDonationAndQuery(DONATION_OPTIONS.FIFTY)} className={donation === DONATION_OPTIONS.FIFTY ? style.active : ''} type='none'>$50</button>
        <button onClick={() => setDonationAndQuery(DONATION_OPTIONS.HUNDRED)} className={donation === DONATION_OPTIONS.HUNDRED ? style.active : ''} type='none'>$100</button>
      </div>

      <div className={style.button} onClick={sendToCheckout}>Proceed To Checkout</div>
    </div>
  )
}
