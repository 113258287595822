import React from 'react'

export default class Sponsor extends React.Component {
  render () {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <h1>Sponsorship Offers</h1>
        <div
          style={{
            textAlign: 'center',
            marginBottom: '16px',
            maxWidth: '400px'
          }}
        >
          Please consider sponsoring the Wiffle Ball Open. Sponsorships help us
          cover course maintenance and ensure the future of the PGA. For more
          information, contact us by August 23, 2021 at{' '}
          <a href='mailto:wiffleballopen@gmail.com'>wiffleballopen@gmail.com</a>
        </div>
        <h2>Par Package</h2>
        <div style={{ textAlign: 'center', marginBottom: '32px' }}>
          $50 Sponsorship <br />
          One Wiffle Ball Open entrance fee waived<br />
          You and a guest will receive breakfast tickets<br />
          Sponsorship represented on one hole
        </div>
        <h2>Birdie Package</h2>
        <div style={{ textAlign: 'center', marginBottom: '32px' }}>
          $75 Sponsorship <br />
          Two Wiffle Ball Open entrance fees waived<br />
          You and up to two guests will receive breakfast tickets <br />
          Sponsorship represented on one hole <br />
          Recognition at trophy presentation <br />
        </div>
        <h2>Eagle Package</h2>
        <div style={{ textAlign: 'center', marginBottom: '32px' }}>
          $100 Sponsorship <br />
          Three Wiffle Ball Open entrance fees waived<br />
          You and up to three guests will receive breakfast tickets <br />
          Unlimited wiffle balls throughout the tournament <br />
          Special Sponsorship Representation <br />
          Recognition at trophy presentation
        </div>
        <h2>Hole-in-One Package</h2>
        <div style={{ textAlign: 'center', marginBottom: '32px' }}>
          $250+ Sponsorship <br />
          Four Wiffle Ball Open entrance fees waived<br />
          You and up to four guests will receive breakfast tickets <br />
          Unlimited wiffle balls throughout the tournament <br />
          Unlimited drinks throughout the tournament <br />
          Special Sponsorship Representation <br />
          Special Recognition at trophy presentation <br />
          Recognized as the tournament's title sponsor
        </div>
      </div>
    )
  }
}
