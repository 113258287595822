import c from 'classnames'
import React from 'react'

import style from './style.css'
import '../../../img/logo.png'

export default ({ title, menuButtonCb }) => {
  return (
    <div className={c(style.wrapper, 'titleBar-type')}>
      <div className={style.hamburger} onClick={menuButtonCb}>
        <i className='material-icons'>menu</i>
      </div>
      <div className={style.title}>{title}</div>
    </div>
  )
}
