import React from 'react'

import style from './style'

export default () => {
  return (
    <div className={style.wrapper}>
      <div className={style.row}>
        <div className={style.name}>
          Samuel S. Poulton<br />
          Commissioner of the PGA
        </div>
        <div className={style.name}>
          Henry J. Poulton<br />
          Assistant to the Commissioner
        </div>
      </div>
      <div className={style.row}>
        <div className={style.name}>
          Zeke W. Poulton<br />
          Senior Reinstated President Emeritus (inactive)
        </div>
        <div className={style.name}>
          M. Patrick Collier<br />
          Chief Technology Officer
        </div>
      </div>
      <div className={style.row}>© 2022 Poulton Golfers' Association</div>
      <div className={style.signature} style={{ justifyContent: 'flex-start' }}>
        Made with ♥ by Michael Collier
      </div>
    </div>
  )
}
