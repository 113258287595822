exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (min-width: 768px) {\n  .wrapper__3T {\n    width: 768px;\n  }\n}\n\n@media (max-width: 768px) {\n  .wrapper__3T {\n    width: 100%;\n  }\n}\n\n.wrapper__3T {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-top: 8px;\n}\n\n.row__26 {\n  display: flex;\n  flex-direction: row;\n  font-size: 8px;\n  justify-content: space-around;\n  margin-bottom: 8px;\n  width: 100%;\n}\n\n.name__1X {\n  font-size: 10px;\n  width: 138px;\n}\n\n.signature__1G {\n  font-size: 8px;\n  margin-bottom: 8px;\n  opacity: 0;\n  transition: opacity .25s;\n}\n\n.signature__1G:hover {\n  opacity: 100;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "wrapper__3T",
	"row": "row__26",
	"name": "name__1X",
	"signature": "signature__1G"
};