import c from 'classnames'
import { map } from 'lodash'
import React from 'react'
import { Link } from 'react-router'

import style from './style.css'

const isCurrent = item => window.location.href.indexOf(item.to) > 0

export default ({ isOpen, items, onClose }) => {
  return (
    <div className={style.wrapper}>
      <div className={c(style.navWrapper, { [style.navWrapperShow]: isOpen })}>
        <div className={c(style.navHeader, 'titleBar-type')}>
          <i className='material-icons' onClick={onClose}>
            menu
          </i>Navigation Menu
        </div>
        <div className={style.navBody}>
          {map(items, item => (
            <Link
              className={c(style.navItem, {
                [style.navItemCurrent]: isCurrent(item)
              })}
              key={item.to}
              onClick={onClose}
              to={item.to}
            >
              {item.title}
            </Link>
          ))}
        </div>
      </div>
      {isOpen && <div className={style.shadow} onClick={onClose} />}
    </div>
  )
}
