import React from 'react'

import style from '../home/style.css'

export default class History extends React.Component {
  render () {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <h1>PGA History</h1>
        <div>
          As a young visionary and cockeyed optimist, Ezekiel Poulton (Reinstated President
          Emeritus, inactive) founded the Poulton Golfers’ Association in 2008. As part of
          the association, Zeke created a simple but high stakes game of
          backyard golf using only one golf club and a plastic wiffle ball.
          Since then, this summertime affair has grown into an annual tournament
          which has hosted hundreds of competitors vying to be the prestigious
          Wiffle Ball Open champion. This year, the PGA continues its legacy by
          celebrating the 14th annual Wiffle Ball Open.
        </div>
        <h2>Previous Winners</h2>
        <div className={style.pastWinners}>
          <ul>
            <li>2022 - Ezekiel Poulton</li>
            <li>2021 - Henry Poulton</li>
            <li>2019 - Henry Poulton</li>
            <li>2018 - Henry Poulton</li>
            <li>2017 - Christian Forbes</li>
            <li>2016 - Christian Ivins</li>
            <li>2015 - Michael Yates</li>
            <li>2013 - Jeff Hatch</li>
            <li>2012 - Michael Yates</li>
            <li>2011 - Michael Yates</li>
            <li>2010 - Henry Poulton</li>
            <li>2009 - Ezekiel Poulton</li>
            <li>2008 - Samuel Poulton</li>
          </ul>
          <h2>2021 Video</h2>
          <iframe
            className={style.videoWrapper}
            src="https://www.youtube.com/embed/52870qS7EHk?controls=0"
            frameborder="0"
            allow="autoplay; encrypted-media;"
            allowfullscreen
            />
          <h2>2017 Video</h2>
          <iframe
            className={style.videoWrapper}
            allow='autoplay; encrypted-media'
            allowfullscreen
            frameborder='0'
            src='https://www.youtube.com/embed/Bb0po6nOd7k'
          />
          <h2>2016 Video</h2>
          <iframe
            className={style.videoWrapper}
            allow='autoplay; encrypted-media'
            allowfullscreen
            frameborder='0'
            src='https://www.youtube.com/embed/h0X7-_jM3MM'
          />
        </div>
      </div>
    )
  }
}
