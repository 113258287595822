exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 768px) {\n  .pageWrapper__2x {\n    height: 100%;\n  }\n\n  .contentWrapper__1R {\n    width: calc(100vw  - 45px);\n  }\n}\n\n@media (min-width: 768px) {\n  .contentWrapper__1R {\n    width: 736px;\n  }\n  .pageWrapper__2x {\n    height: 100%;\n  }\n}\n\n.pageWrapper__2x {\n  align-items: center;\n  background-color: #e5e5e5;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.pageWrapper__2x a {\n  color: unset;\n  text-decoration: none;\n}\n\n.pageWrapper__2x h1 {\n  font-size: 30px;\n  font-weight: bold;\n  margin-bottom: 24px;\n}\n\n.pageWrapper__2x h2 {\n  font-size: 20px;\n  font-weight: bold;\n  margin: 8px 0;\n}\n\n.contentWrapper__1R {\n  align-items: center;\n  background-color: white;\n  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.12);\n  display: flex;\n  flex-direction: column;\n  padding: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"pageWrapper": "pageWrapper__2x",
	"contentWrapper": "contentWrapper__1R"
};