import React from 'react'

import style from '../home/style.css'

export default class Home extends React.Component {
  render () {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <h1>Tournament Information</h1>
        <ol className={style.list} style={{ listStyleType: 'decimal' }}>
          <li>
            <div>
              The Wiffle Ball Open is a wiffle golf tournament composed of four
              rounds on a 7-hole course using a golf club and a plastic wiffle
              ball. Like regular golf, it is completely fun and frustrating at
              the same time.
            </div>
          </li>
          <li>
            The tournament will commence June 11, 2022 at 2020 Ridgehill Drive,
            Bountiful, Utah.
          </li>
          <li>
            Tee times range from early morning to midday. Preferred tee times
            can be submitted when you RSVP.
          </li>
          <li>
            Tee times will be emailed to participants two days in advance
            (June 9th).
          </li>
          <li>
            Format: Competitors will play two back-to-back rounds. Then,
            competitors will have an hour for eating, visiting, and gloating.
            Finally, the last two rounds will be played. Participants can expect
            these activities to take roughly 2 to 3 hours.
          </li>
          <li>
            In order to play you must RSVP by the date and in the manner
            described{' '}
            <a href='#/rsvp' style={{ color: '#067890' }}>
              here.
            </a>
          </li>
        </ol>

        <div className={style.italic} style={{ marginBottom: '8px' }}>
          Guests of all ages are invited to watch, play in, and enjoy the
          tournament.
        </div>
        <div style={{ marginBottom: '16px' }}>
          In order to play in the tournament{' '}
          <span className={style.strong}>
            <u>EACH player must RSVP</u>
          </span>{' '}
          before{' '}
          <span className={style.strong}>
            <u>Wednesday June 8th, 2022</u>
          </span>{' '}
          otherwise you{' '}
          <span className={style.strong}>
            <u>will not</u>
          </span>{' '}
          be permitted to compete. Sign-ups are on a first come first serve
          basis and space may be limited.
        </div>
        <div style={{ marginBottom: '16px' }}>
          <div style={{ marginBottom: '8px' }}>
            Requirements for competitors:
          </div>
          <ul className={style.list}>
            <li>$10 tournament fee</li>
            <li>
              You will need to bring one club. It must be an 8 iron, 9 iron, or
              a wedge. (If you don't have a golf club please let us know)
            </li>
            <li>3 wiffle balls will be given to each participant</li>
            <li>Additional wiffleballs can be purchased for $1 per ball</li>
          </ul>
        </div>
        <div style={{ marginBottom: '16px' }}>
          <div className={style.italic}>
            On the day of the tournament each player must show up at least{' '}
            <span className={style.strong}>15 minutes before</span> their tee
            time, otherwise they will be assessed a 2 stroke penalty.
          </div>
        </div>
        <div style={{ marginBottom: '40px' }}>
          Breakfast or Lunch is included with your entry. Please
          indicate if you plan to bring any non-participants who would like to
          eat with you ($5 per person) when you rsvp.
        </div>
        <div style={{ textAlign: 'center' }}>
          If you have any questions please contact us at:
        </div>
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          <a href='mailto:wiffleballopen@gmail.com'>wiffleballopen@gmail.com</a>
        </div>
      </div>
    )
  }
}
