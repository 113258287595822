exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".navHeader__3j {\n  align-items: center;\n  background-color: #4D8741;\n  color: white;\n  display: flex;\n  font-size: 16px;\n  height: 55px;\n  text-transform: uppercase;\n  width: 100%\n}\n\n.navHeader__3j i {\n  cursor: pointer;\n  padding: 0 16px 0 8px;\n}\n\n.navItem__1B {\n  align-items: center;\n  color: black;\n  display: flex;\n  height: 48px;\n  justify-content: center;\n  letter-spacing: 2px;\n  text-decoration: none;\n  text-transform: uppercase;\n  width: 100%;\n}\n\n.navItemCurrent__OH {\n  background-color: rgba(28,31,98, 0.35);\n}\n\n.navWrapper__2V {\n  background-color: white;\n  height: 100vh;\n  left: -256px;\n  position: absolute;\n  top: 0;\n  transition: all 0.25s;\n  width: 256px;\n  z-index: 2;\n}\n\n.navWrapperShow__1T {\n  left: 0 !important;\n}\n\n.shadow__ut {\n  background-color: black;\n  cursor: pointer;\n  height: 100vh;\n  opacity: 0.8;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  z-index: 1;\n}\n\n.wrapper__1l {\n  display: flex;\n  flex-direction: row;\n}\n", ""]);

// exports
exports.locals = {
	"navHeader": "navHeader__3j",
	"navItem": "navItem__1B",
	"navItemCurrent": "navItemCurrent__OH",
	"navWrapper": "navWrapper__2V",
	"navWrapperShow": "navWrapperShow__1T",
	"shadow": "shadow__ut",
	"wrapper": "wrapper__1l"
};