import React from 'react'

export default function Terms () {
  return (
    <div>
      <h1>Terms of Service</h1>
      <div>It's a golf app for a backyard tournament, what do you expect</div>
    </div>
  )
}
