import React from 'react'

export default function Privacy () {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <div>We don't sell your data</div>
    </div>
  )
}
