import React from 'react'

import style from './style.css'

const quotes = [
  '"The best golf tournament I\'ve ever played in" - Director of Golf and Head Professional',
  '"Badass"',
  `"At first this sounded dumb, but it's actually pretty fun"`,
  '"I look forward to this tournament every year"'
]

const QUOTE_TIMEOUT = 7000

export default class Home extends React.Component {
  constructor () {
    super()
    this.state = {
      quoteIndex: 0
    }
    this.advanceQuoteWheel = this.advanceQuoteWheel.bind(this)
    setTimeout(this.advanceQuoteWheel, QUOTE_TIMEOUT)
  }

  advanceQuoteWheel () {
    let { quoteIndex } = this.state
    if (++quoteIndex === quotes.length) quoteIndex = 0
    this.setState({ quoteIndex })
    setTimeout(this.advanceQuoteWheel, QUOTE_TIMEOUT)
  }

  render () {
    const { quoteIndex } = this.state
    return (
      <div>
        <div className={style.logo}>
          <img src='/logo-1.png' />
        </div>
       {/*<div className={style.buttonWrapper}>
          <a className={style.button} href='https://app.wiffleball.xyz'>
            Start Playing
          </a>
          <a href='/#/checkout'>
            <div className={style.button}>Pay Fee</div>
          </a>
    </div>*/}
      {/*<div className={style.infoWrapper}>
          <div className={style.info}>
            <span>TOURNAMENT DATE</span>
            <span>August 28, 2021</span>
          </div>
          <div className={style.info}>
            <span>RSVP DEADLINE</span>
            <span>August 24, 2021</span>
          </div>
        </div>*/}
      {/*<div
          className={style.italic}
          style={{
            fontSize: '20px',
            marginBottom: '24px',
            textAlign: 'left'
          }}
        >
          All competitors will be entered in a silent raffle with over $250 in
          prizes. There will be 1st, 2nd, and 3rd place prizes for the winners.
          In addition, and more importantly, this year's champion will take home
          the prestigious 2018 Wiffle Ball Open trophy.
        </div>
      */}
      <div className={style.buttonWrapper}>
        {/*<a href='#/rsvp'>
            <div className={style.button}>RSVP HERE</div>
          </a>
          <a href='/#/checkout'>
            <div className={style.button}>PAY FEE</div>
    </a>*/}
          <a href='#/info'>
            <div className={style.button}>INFO</div>
          </a>
        </div>
        <div className={style.quotes}>
          <span>{quotes[quoteIndex]}</span>
        </div>
      </div>
    )
  }
}
