import React from 'react'
import { render } from 'react-dom'
import { Redirect, Router, Route, hashHistory } from 'react-router'

import './css/reset.css'
import './css/style.css'
import './img/favicon.png'
import './img/logo-1.png'

import AppRoot from './app'
import AppMain from './app/main'
import Home from './app/home'

import Checkout from './app/checkout'
import History from './app/history'
import Info from './app/info'
import Privacy from './app/privacy'
import Rsvp from './app/rsvp'
import Sponsor from './app/sponsor'
import Success from './app/success'
import Terms from './app/terms'

const Routes = () => {
  return (
    <Router history={hashHistory}>
      <Redirect from='/' to='/landing' />
      <Route path='/' component={AppRoot}>
        <Route path='landing' component={Home} />
        <Route path='sponsor' component={Sponsor} />
        <Route path='rsvp' component={Rsvp} />
        <Route path='info' component={Info} />
        <Route path='history' component={History} />
        <Route path='app' component={AppMain} />
        <Route path='privacy' component={Privacy} />
        <Route path='tos' component={Terms} />
        <Route path='checkout' component={Checkout} />
        <Route path='success' component={Success} />
      </Route>
      <Route path='*' component={() => <div>404</div>} />
    </Router>
  )
}

const el = document.querySelector('#root')

render(<Routes />, el)
