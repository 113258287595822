import React from 'react'

export default () => {
  const params = new URLSearchParams(window.location.href.split('?')[1])
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <h1>Payment Successful!</h1>
      {params.get('donation') && (<p style={{fontStyle: 'italic', marginBottom: '16px'}}>Thank you for your generosity. Donations help us maintain the high standard of quality that makes the Wiffle Ball Open the most esteemed tournament of the summer.</p>)}
      <div>Your payment has been recieved. We look forward to seeing you!</div>
    </div>
  )
}
