exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".hamburger__xq {\n  cursor: pointer;\n}\n\n.wrapper__31 {\n  align-items: center;\n  background-color: #4D8741;\n  display: flex;\n  height: 55px;\n  min-height: 55px;\n  justify-content: space-between;\n  padding: 0 8px;\n  max-width: 100%;\n  font-size: 16px;\n}\n\n.wrapper__31 a {\n  color: white;\n  text-decoration: none;\n}\n\n.title__3Q {\n  flex: 1;\n  margin-left: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"hamburger": "hamburger__xq",
	"wrapper": "wrapper__31",
	"title": "title__3Q"
};