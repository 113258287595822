import React from 'react'

import style from './style.css'
import Footer from './components/footer'
import TitleBar from './components/title-bar'
import SlideOut from './components/slide-out'

const items = [
  { title: 'Home', to: '/landing' },
  { title: 'Tournament Info', to: '/info' },
  { title: 'RSVP', to: '/rsvp' },
  { title: 'History', to: '/history' },
  // { title: 'Sponsorship', to: '/sponsor' }
]

export default class AppRoot extends React.Component {
  constructor () {
    super()
    this.state = {
      navIsOpen: false
    }
    this.toggleSlideOut = this.toggleSlideOut.bind(this)
  }

  toggleSlideOut () {
    this.setState({ navIsOpen: !this.state.navIsOpen })
  }

  render () {
    const { navIsOpen } = this.state
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <SlideOut
          isOpen={navIsOpen}
          onClose={this.toggleSlideOut}
          items={items}
        />
        <TitleBar menuButtonCb={this.toggleSlideOut} title='Wiffle Ball Open' />
        <div style={{ flex: 1 }}>
          <div className={style.pageWrapper}>
            <div className={style.contentWrapper}>{this.props.children}</div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}
