import React from 'react'

import style from '../home/style.css'

export default class Rsvp extends React.Component {
  render () {
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h1>RSVP Instructions</h1>
        <ol className={style.list} style={{ listStyleType: 'decimal', fontSize: '24px'}}>
      {/*<li>Fill out the RSVP form by clicking the button below.</li>
          <li>
            If you are signing up for more than one person you will need to
            submit one form for each person playing simply by clicking “submit
            another response” or re-clicking the button below.
            <div
              className={style.italic}
              style={{ marginTop: '8px', textAlign: 'left' }}
            >
              These steps ensure each person is accounted for and allotted a
              first round tee time.
            </div>
          </li>
          <li>
            Ensure you enter a valid email address as the first round tee times
            will be sent to you two nights before the tournament begins (August
            26th).
          </li>
          <li>
            <a className={style.link} href="/#/checkout">
              Click here to complete your payment online
            </a>
          </li>
          */}
        <li>
          <div>Fill out the RSVP form:</div>
          <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <br style={{marginTop: '8px'}} />
            <div style={{fontStyle: 'italic'}}>A form must be filled out for <span style={{textDecoration: 'underline'}}>each</span> competitor in your party</div>
            <br style={{marginTop: '8px'}} />
            <a style={{marginRight: '50px'}} href='https://forms.gle/btMHaSH8oFami54r9' target="_blank">
              <div className={style.button}>RSVP Form</div>
            </a>
          </div>
        </li>
        <li>
          <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <div style={{alignSelf: 'flex-start'}}>Pay tournament entry fee:</div>
            <br style={{marginTop: '8px'}} />
          <a style={{marginRight: '50px'}} href='/#/checkout'>
            <div className={style.button}>Pay Fee</div>
          </a>
      </div>
        </li>
        </ol>
      </div>
    )
  }
}
